import React from "react"
import { Link } from "gatsby"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import module from '../styles/components/modules/about.module.scss';

const About = () => (
  <div className={module.wrapper}>
    <SEO title="About" />
    <div className={module.container}>
      <div className={module.top}>
        <h1 className={module.title}>About</h1>
        <div className={module.copy}>
          <p>This is the startpage. It's currently <i>very</i> much a work in progress.</p>
          <p>Objects are currently hard-coded in the data file, but that will change.</p>
          
          <div className={module.issues_block}>
            <p className={module.issues_title}>Here are some things that I'd like to edit/fix/expand in the future:</p>
            <ul className={module.issues_list}>
              <li>Make the data customizable from localstorage, likely making a feature for import/export of lists</li>
              <li>Automatic backups of data to user storage (S3, Dropbox, file)</li>
              <li>Expand the header/footer options, add customizable widgets</li>
              <li>User CSS tweaks, maybe a theme switcher?</li>
            </ul>
          </div>

          <p className={module.updated_copy}>More as it's updated.</p>
          
          <p className={module.src_link}>Source is available at <a href="https://github.com/ff4500/startpage/" target="_blank" rel="noopener noreferrer">github.com/ff4500/startpage</a>.</p>
        </div>
      </div>
      <Link className={module.home_link} to="/">back</Link>
    </div>
  </div>
)

export default About
